import App from './App.vue'
import { createApp } from 'vue'
import { IonicVue } from '@ionic/vue'
import { createPinia } from 'pinia'
import vSelect from 'vue-select'
import { App as capacitorApp, type URLOpenListenerEvent } from '@capacitor/app'

import router from './router'
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'
// import VueSweetalert2 from 'vue-sweetalert2'
// import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue-select/dist/vue-select.css'

import './registerServiceWorker'

/* Theme variables */
import './theme/main.scss'

const pinia = createPinia()

const app = createApp(App).use(pinia).use(IonicVue).use(router)
app.component('VSelect', vSelect)
router.isReady().then(() => {
  app.mount('#app')
})
capacitorApp.addListener('appUrlOpen', function (event: URLOpenListenerEvent) {
  // Example url: https://beerswift.app/tabs/tabs2
  // slug = /tabs/tabs2
  const slug = event.url.split('com.au').pop()

  // We only push to the route if there is a slug present
  if (slug) {
    router.isReady().then(() => {
      router.push({
        path: slug,
      })
    })
  }
})
