
import { defineComponent } from 'vue'
import Menu from '../../components/Global/Menu.vue'
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonIcon,
  IonPage,
  IonRouterOutlet,
} from '@ionic/vue'
import { home, list, calendarClear, peopleCircle } from 'ionicons/icons'
export default defineComponent({
  name: 'Tabs',
  components: {
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
    IonRouterOutlet,
    Menu,
  },
  setup() {
    return {
      home,
      list,
      peopleCircle,
      calendarClear,
    }
  },
})
