import type {
  dashboardAppointment,
  chartsSections,
} from '@/app/types/dashboard'
import dayjs from 'dayjs'
import { computed, ref } from 'vue'
import {
  Chart,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale,
  Filler,
} from 'chart.js'

type ExerciseProgress = 'up-to-date' | 'in-progress' | 'not-started'

export type DashboardItem = {
  appointmentId: number
  appointmentDate: string
  provider: {
    id: number
    name: string
    image: any
  }
  exerciseProgress: ExerciseProgress
  charts: chartsSections[]
  recoveryAppointments: ({
    date: string
  } & dashboardAppointment['Escalation'])[]
  condition: string
  appointmentsCount: number
  exerciseCharts: chartsSections[]
  dischargedReason: false | string
  nextAppointment: false | string
}

export const useDashboard = (registerCharts = true) => {
  if (registerCharts) {
    Chart.register(
      Title,
      Tooltip,
      Legend,
      Filler,
      LineElement,
      LinearScale,
      PointElement,
      CategoryScale
    )
  }

  const getExerciseProgress = (
    appointment: dashboardAppointment
  ): ExerciseProgress => {
    let status: ExerciseProgress = 'not-started'
    if (!appointment.exercise_report) return status

    const days = appointment.exercise_report.daysToRecord.filter((day) => {
      return dayjs(day).isBefore(dayjs().add(1, 'day'), 'day')
    })

    const recordedDays = Object.keys(appointment.exercise_report.recordedData)

    if (days.length === recordedDays.length) {
      // if days in "recordedDays" is same as days in "days" then all days are recorded
      let isUpTuDate = true
      for (const [index, day] of days.entries()) {
        if (!dayjs(day).isSame(dayjs(recordedDays[index]), 'day')) {
          isUpTuDate = false
          break
        }
      }
      status = isUpTuDate ? 'up-to-date' : 'in-progress'
    } else if (recordedDays.length > 0) {
      status = 'in-progress'
    }

    return status
  }

  const chartOptions = (
    min?: number,
    max?: number
  ): chartsSections['elements'][0]['chartOptions'] => ({
    responsive: true,
    spanGaps: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    ...(min !== undefined &&
      max !== undefined && {
        scales: {
          y: {
            min,
            max,
          },
        },
      }),
  })

  const formatChart = (
    name: string,
    data: {
      label: string
      minMax?: [number, number]
      labels: string[]
      data: number[]
      dataName: string
    }[],
    datasetStyle: any = {
      backgroundColor: 'rgba(43, 51, 151, 1)',
      borderColor: 'rgba(43, 51, 151, 1)',
      borderWidth: 1,
    }
  ): chartsSections => ({
    name,
    elements: data.map((d) => ({
      chartLabel: d.label,
      chartOptions: d.minMax
        ? chartOptions(d.minMax[0], d.minMax[1])
        : chartOptions(),
      chartData: {
        labels: d.labels,
        datasets: [
          {
            label: d.dataName,
            data: d.data,
            ...datasetStyle,
          },
        ],
      },
    })),
  })

  const getExerciseCharts = (
    appointment: dashboardAppointment
  ): chartsSections[] => {
    if (!appointment.exercise_report) return []
    const data: {
      id: number
      title: string
      data: {
        date: string
        score: number
      }[]
    }[] = []
    Object.keys(appointment.exercise_report.recordedData).forEach((date) => {
      const { exercises } = appointment.exercise_report.recordedData[date]

      for (const exe of exercises) {
        const exercise = data.find((el) => el.id == exe.id)
        const d = {
          date,
          score: exe.painScale,
        }

        const exerciseData = appointment.exercise_report.exerciseData.find(
          (el) => el.exercise?.id == exe.id
        )
        if (!exercise) {
          data.push({
            id: exe.id,
            title: exerciseData?.exercise?.name || `Exercise ${exe.id}`,
            data: [d],
          })
        } else {
          exercise.data.push(d)
        }
      }
    })

    const charts: chartsSections[] = data.map((d) => {
      const chartData = {
        labels: d.data.map((el) => dayjs(el.date).format('DD/MM')),
        datasets: [
          {
            label: d.title,
            data: d.data.map((el) => el.score),
            backgroundColor: 'rgba(45, 212, 255, 1)',
            borderColor: 'rgba(45, 212, 255, 1)',
            borderWidth: 1,
          },
        ],
      }
      return {
        name: d.title,
        elements: [
          {
            chartLabel: '',
            chartOptions: chartOptions(0, 10),
            chartData,
          },
        ],
      }
    })

    return charts
  }

  const getCharts = (appointment: dashboardAppointment): chartsSections[] => {
    const charts: chartsSections[] = []
    type AppointmentsInArray = dashboardAppointment[]
    const appointmentsInArray: AppointmentsInArray = [] as AppointmentsInArray

    const formatAppointment = (app: dashboardAppointment) => {
      appointmentsInArray.push({
        ...app,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        children: undefined,
      })
      if (app.children && app.children.sessionEndTime)
        formatAppointment(app.children)
    }

    formatAppointment(appointment)

    const painScale = formatChart('Pain Scales', [
      {
        minMax: [0, 10],
        label: 'Pain Scale',
        labels: appointmentsInArray.map((app) =>
          dayjs(app.sessionEndTime).format('DD/MM')
        ),
        data: appointmentsInArray.map((app) => app.painScale || 0),
        dataName: 'Pain Scale',
      },
    ])
    charts.push(painScale)

    const psfs = formatChart(
      'Patient Functional Scales',
      appointment.psfsDetails
        .filter((psfs) => psfs.activity)
        .map((psfs) => ({
          minMax: [0, 10],
          label: psfs.activity.title,
          labels: appointmentsInArray.map((app) =>
            dayjs(app.sessionEndTime).format('DD/MM')
          ),
          data: appointmentsInArray.map(
            (app) =>
              app.psfsDetails.find((p) => p.activity.id === psfs.activity.id)
                ?.difficultyLevel || 0
          ),
          dataName: psfs.activity.title,
        }))
    )
    charts.push(psfs)

    if (appointmentsInArray[0].otherMeasures[0]?.measure) {
      const measure = formatChart('Patient Reported Measures', [
        {
          label: appointmentsInArray[0].otherMeasures[0].measure.title,
          labels: appointmentsInArray.map((app) =>
            dayjs(app.sessionEndTime).format('DD/MM')
          ),
          dataName: appointmentsInArray[0].otherMeasures[0].measure.title,
          data: appointmentsInArray.map((app) => app.otherMeasures?.[0]?.score),
        },
      ])
      charts.push(measure)
    }

    return charts
  }

  const getRecoveryAppointments = (
    appointment: dashboardAppointment
  ): ({
    date: string
  } & dashboardAppointment['Escalation'])[] => {
    const appointments: ({
      date: string
    } & dashboardAppointment['Escalation'])[] = []

    const formatAppointment = (app: dashboardAppointment) => {
      if (app.children && app.children.sessionEndTime && app.Escalation)
        formatAppointment(app.children)

      if (app.Escalation)
        appointments.push({
          date: app.sessionEndTime,
          ...app.Escalation,
        })
    }

    formatAppointment(appointment)

    return appointments
  }

  const getAppointmentsCount = (appointment: dashboardAppointment) => {
    let count = 1
    const c = (app: dashboardAppointment) => {
      if (app.children && app.children.sessionEndTime) {
        count++
        c(app.children)
      }
    }
    c(appointment)
    return count
  }

  const dashboardItems = ref<DashboardItem[]>([])

  const setData = (data: dashboardAppointment[]) => {
    for (const item of data) {
      const lastAppointment = item.latestChild || item
      const provider = {
        id: lastAppointment.provider.id,
        name: `${lastAppointment.provider.firstName} ${lastAppointment.provider.lastName}`,
        image: lastAppointment.provider.profileImg,
      }

      const exerciseProgress = getExerciseProgress(item)
      const charts = getCharts(item)
      const recoveryAppointments = getRecoveryAppointments(item)

      const exerciseCharts = getExerciseCharts(item)

      dashboardItems.value.push({
        appointmentId: lastAppointment.id,
        appointmentDate: lastAppointment.sessionEndTime,
        provider,
        exerciseProgress,
        charts,
        recoveryAppointments,
        condition: lastAppointment.condition?.name || 'Unknown condition',
        appointmentsCount: getAppointmentsCount(item),
        exerciseCharts,
        dischargedReason: lastAppointment.dischargeReason?.title || false,
        nextAppointment:
          !item.latestChild ||
          item.latestChild.sessionEndTime ||
          item.latestChild.sessionStartTime
            ? false
            : item.latestChild.appointmentTimeStart,
        // @ts-expect-error dfs
        lc: item.latestChild,
      })
    }
  }
  return {
    setData,
    // not discharged displayed first
    dashboardItems: computed(() =>
      dashboardItems.value.sort((a, b) => {
        if (a.dischargedReason && !b.dischargedReason) return 1
        if (!a.dischargedReason && b.dischargedReason) return -1
        return 0
      })
    ),
  }
}
