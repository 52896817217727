import { authService } from '@/app/core/_services/auth-service'
//Form Settings - TODO: legacy
const LABEL = {
  AUTHENTICATION: {
    EMAIL: 'Email',
    LOGIN: 'Login',
    LOGIN_HEADER: 'Sign in to book your appointment',
    PASSWORD: 'Password',
    REGISTER: 'Register',
    RECOVER_YOUR_PASSWORD: 'Recover Your Password',
    RESET_PASSWORD: 'Reset Password',
    CHANGE_MY_PASSWORD: 'Change My Password',
    NEW_PASSWORD: 'New Password',
    CONFIRM_PASSWORD: 'Confirm Password',
    FORGOT_PASSWORD_LINK: 'Forgot Password?',
    SEND_RESET_PASSWORD_LINK: 'Send Reset Password Link',
    RESET_MY_PASSWORD: 'Reset My Password',
    FORGOT_PASSWORD: 'Forgot Password',
    SIGN_UP: 'Sign Up',
    YOUR_NAME: 'Your Name',
    MOBILE: 'Mobile',
    OTP_VERIFICATION: 'OTP Verification',
    OTP: 'OTP',
    RESEND_OTP: 'Resend OTP',
    SUBMIT_OTP: 'Submit OTP',
    CHANGE_PASSWORD: 'Change Password',
  },
  PROFILE: {
    HEADER: 'Profile',
  },
}
// // Randomiser appended to autocomplete to disable autocomplete
// const randAutoComplete = () => {
//   return Math.random()
//     .toString(36)
//     .replace(/[^a-z]+/g, '')
// }

//Validation Patterns - TODO: legacy
const VALIDATOR = {
  EMAIL_PATTERN: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,8}$/,
  TEXT: /^[dA-Za-z]+[A-Za-z ]$/,
  TEXT_NUMBERS: '^[dA-Za-z0-9]+[A-Za-z0-9 ]*',
  TEXT_NUMBERS_SPECIAL: /^[a-zA-Z0-9\n@,-:;"',+<>.?*!/|{}#$%^&()=-_ ]*$/,
  NUMBERS: '^[0-9]*$',
  //   MOBILE: /^[45]\d{8}$/,
  MOBILE:
    /^0(4|5)(\s?[0-9]{2}\s?)([0-9]{3}\s?[0-9]{3}|[0-9]{2}\s?[0-9]{2}\s?[0-9]{2})$/,
  OTP: '^[0-9]{4,10}$',
  PASSWORD:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@,-:;"',+<>.?*!/|{}#$%^&()=-_]{8,32}$/,
  ZIPCODE: /^[0-9]{4,4}$/,
  TEXT_NUMBERS_SPECIAL_CHAR: /^([\w]{1,})([^0-9_a-zA-Z%$#\s])?/,
  NAME: /^[A-Za-z ]*$/,
}

const validBornDays = () => {
  const range = [...Array(32).keys()]
  range.shift()
  return range.map((value) => {
    const day = value < 10 ? `0${value}` : value
    return { value: day, label: day }
  })
}
const validBornMonths = () => {
  const range = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ]
  // range.shift()
  return range.map((value, index) => {
    const i = index + 1
    const day = i < 10 ? `0${i}` : i
    return { value: day, label: `${day} (${value})` }
  })
}
const validBornYears = () => {
  const validAges = [18, 100]
  const now = new Date().getUTCFullYear()
  const years = Array(now - (now - validAges[1]))
    .fill('')
    .map((v, idx) => now - validAges[0] - idx)
  return years.map((year) => {
    return { value: year, label: year }
  })
}
const getNotificationCount = (itemName: string) => {
  const notificationCount = '0'
  return notificationCount
}

import {
  mailOutline,
  personOutline,
  lockClosedOutline,
  //   callOutline,
  phonePortraitOutline,
  home,
  personCircle,
  person,
  exit,
  list,
  calendarClear,
  barbellOutline,
  receiptOutline,
} from 'ionicons/icons'

export const lang = {
  LABEL,
  // alerts
  alert: {
    CONFIRMATION: 'Are you sure?',
    CONFIRMATION_YES: 'Yes',
    CONFIRMATION_NO: 'No',
  },
  //Spinner Messages
  spinner: {
    general: 'Please wait a moment ...',
    open: 'Please wait a moment ...',
    show: 'Processing ...',
  },
  //Toast Notification Alerts
  notification: {
    error: {
      general: {
        header: `Sorry, but we've encounted an issue`,
        message:
          'Please check your details and try again. Or contact our support team for further help.',
      },
      confirmEmail: {
        header: 'Sorry, but we could not log you in',
        message: 'Your account email is not confirmed',
      },
      incorrectPassword: {
        header: 'Sorry, but we could not log you in',
        message: 'Please check your email address and password are correct.',
      },
      multipleAttempts: {
        header: 'Trouble signing in?',
        message: 'Click "Forgot" to reset your password.',
      },
      userDoesNotExist: {
        header: 'Sorry, but we could not log you in',
        message: 'Please check the information you have supplied is correct.',
      },
      underAge: {
        header: 'We can not complete your registration',
        message: `By law we cannot accept registrations that are under age without a parent or guardian's content. You will require an authorised person to register on your behalf.`,
      },
      invalidDOB: {
        header: 'Invalid Date of Birth',
        message: `Please check your date of birth is correct and try again. Contact our support team for further help if required.`,
      },
    },
    success: {
      general: {
        header: 'Success',
        message: 'Your request has been completed.',
      },
      verifyEmail: {
        header: 'Your verification email has been sent',
        message:
          'Open your email account with instructions for resetting your password.<br /><br />If you have not received an email check your SPAM folder or contact our support team for further help.',
      },
    },
  },
  //Forms General
  form: {
    post: {
      response: {
        //To clean up
        config: 'Arguments Invalid',
        address: 'Check your address and zipCode properly',
      },
    },
    error: {
      general: {
        required: 'This field is required',
        invalid: 'This field is invalid',
        min: 'Not enough characters',
        max: 'Too many characters',
      },
      email: {
        required: 'Please enter an email address',
        invalid: 'Please confirm your email address is correct',
      },
      password: {
        required: 'Please enter a password',
        invalid: 'Your password is not secure',
      },
    },
    input: {
      email: {
        placeholder: 'yourname@email.com',
      },
      password: {
        placeholder: 'password',
      },
      submit: {
        text: 'Submit',
      },
    },
    //Form
    login: {
      input: {
        submit: {
          text: 'Login',
        },
        forgotPassword: {
          text: 'Forgot?',
        },
      },
    },
    forgotPassword: {
      input: {
        submit: {
          text: 'Verify your email address',
        },
      },
    },
    registrationForm: {
      input: {
        submit: {
          text: 'Register ',
        },
      },
      settings: {
        validAge: 18,
      },
      inputs: [
        {
          slot: 'start',
          icon: personOutline,
          name: 'firstName',
          //   label: 'First Name',
          type: 'text',
          placeholder: 'First Name',
          required: true,
        },
        {
          slot: 'end',
          name: 'lastName',
          //   label: 'Last Name',
          type: 'text',
          placeholder: 'Last Name',
          required: true,
        },
        {
          name: 'email',
          //   label: 'Email Address',
          icon: mailOutline,
          autocomplete: 'new-email',
          type: 'email',
          placeholder: 'yourname@email.com',
          error: {
            invalid: 'Please ensure your email address is correct',
          },
          required: true,
          pattern: VALIDATOR.EMAIL_PATTERN,
        },

        // {
        //   slot: 'start',
        //   name: 'countryCode',
        //   class: 'is-short',
        //   label: 'Country',
        //   type: 'tel',
        //   placeholder: '+61',
        //   required: true,
        //   value: '+61',
        //   readonly: true,
        // },
        {
          icon: phonePortraitOutline,
          name: 'mobileNumber',
          //   label: 'Contact Number',
          type: 'tel',
          placeholder: '0400 000 000',
          pattern: VALIDATOR.MOBILE,
          error: {
            invalid: 'Please ensure your mobile contact number is correct',
          },
          required: true,
          tooltip:
            'Please enter the 9 digit mobile number without the country code',
        },
        {
          name: 'password',
          icon: lockClosedOutline,
          //   label: 'Password',
          type: 'password',
          placeholder: 'Enter a Password',
          pattern: VALIDATOR.PASSWORD,
          required: true,
          error: {
            invalid:
              'Password must be between 8 and 32 characters with a mix of lower, uppercase and special characters',
          },
          tooltip:
            'Password should be between 8 to 32 characters with a combination of Upper case, Lower Case and Numbers',
        },
        // {
        //   name: 'confirmPassword',
        //   //   label: 'Confirm',
        //   type: 'password',
        //   placeholder: 'Confirm your Password',
        //   required: true,
        //   tooltip:
        //     'Password should be between 8 to 32 characters with a combination of Upper case, Lower Case and Numbers',
        // },
        {
          name: 'dobDay',
          slot: 'start',
          label: 'Birthday',
          position: 'stacked',
          type: 'select',
          placeholder: 'Day',
          required: true,
          options: validBornDays(),
        },
        {
          name: 'dobMonth',
          label: 'Month',
          position: 'stacked',
          slot: 'start',
          type: 'select',
          placeholder: 'Month',
          required: true,
          options: validBornMonths(),
        },
        {
          name: 'dobYear',
          slot: 'end',
          label: 'Year',
          position: 'stacked',
          type: 'select',
          placeholder: 'Year',
          required: true,
          options: validBornYears(),
        },
        {
          name: 'genderId',
          label: 'Gender',
          type: 'select',
          position: 'stacked',
          placeholder: 'What gender do you relate to?',
          required: true,
          options: [
            {
              label: 'Male',
              value: 'male',
            },
            {
              label: 'Female',
              value: 'female',
            },
            {
              label: 'Other',
              value: 'other',
            },
          ],
        },
        // {
        //   name: 'dob',
        //   type: 'hidden',
        // },
        //If other is selected, allow them to choose their sex and gender preference
        // {
        //     name: 'genderId',
        //     label: 'Gender',
        //     type: 'select',
        //     placeholder: 'What gender do you relate to?',
        //     required: true,
        //     options: [
        //       {
        //         label: 'Male',
        //         value: '36',
        //       },
        //       {
        //         label: 'Female',
        //         value: '37',
        //       },
        //       {
        //         label: 'Other',
        //         value: '38',
        //       },
        //     ],
        //   },
        //Address Form

        {
          name: 'addressType',
          label: 'Location',
          position: 'stacked',
          type: 'select',
          placeholder: 'What is your preferred location?',
          required: true,
          options: [
            {
              label: 'At Home',
              value: 'home',
            },
            {
              label: 'At Work',
              value: 'work',
            },
            {
              label: 'Somewhere else',
              value: 'other',
            },
          ],
        },
        {
          name: 'address',
          // isAddress: true,
          // label: 'Address Lookup',
          // position: 'stacked',
          // placeholder: "Search for this location's address ",
          // type: 'text',
          // // required: true,
          // autocomplete: `street-address ${randAutoComplete()}`,
        },
        // {
        //   slot: 'start',
        //   name: 'streetAddress',
        //   isAddress: true,
        //   label: 'Street Address',
        //   position: 'stacked',
        //   placeholder: '9 Street Road',
        //   type: 'text',
        //   required: true,
        //   autocomplete: `address ${randAutoComplete()}`,
        // },
        // {
        //   slot: 'end',
        //   name: 'apartment',
        //   isAddress: true,
        //   label: 'Unit Number',
        //   type: 'text',
        //   position: 'stacked',
        //   placeholder: 'Unit 9',
        //   autocomplete: `address-line1 ${randAutoComplete()}`,
        // },
        // {
        //   name: 'city',
        //   isAddress: true,
        //   label: 'City',
        //   position: 'stacked',
        //   type: 'text',
        //   placeholder: 'City or Suburb',
        //   required: true,
        //   autocomplete: `address-level2 ${randAutoComplete()}`,
        // },
        // {
        //   slot: 'start',
        //   isAddress: true,
        //   name: 'state',
        //   label: 'State',
        //   position: 'stacked',
        //   type: 'text',
        //   placeholder: 'State',
        //   required: true,
        //   autocomplete: `address-level1 ${randAutoComplete()}`,
        // },
        // {
        //   slot: 'end',
        //   isAddress: true,
        //   name: 'zipCode',
        //   label: 'Postcode',
        //   position: 'stacked',
        //   type: 'text',
        //   placeholder: 'Postcode',
        //   required: true,
        //   autocomplete: `postal-code ${randAutoComplete()}`,
        // },
        // {
        //   type: 'map',
        // },
        // {
        //   name: 'country',
        //   label: 'Country',
        //   type: 'tel',
        //   position: 'stacked',
        //   placeholder: 'Country',
        //   //   value: 'Australia',
        //   required: true,
        // },
        {
          name: 'parkingType',
          label: 'Parking',
          type: 'select',
          position: 'stacked',
          placeholder: 'What type of parking is available?',
          required: true,
          options: [
            { label: 'Driveway', value: 'driveway' },
            { label: 'On-Street', value: 'on street' },
            { label: 'Off-Street', value: 'off street' },
            { label: 'Metered', value: 'metered' },
          ],
        },
        {
          name: 'stairs',
          label: 'Are stairs used to enter the property?',
          type: 'checkbox',
          value: '1',
        },
        {
          name: 'petAnimals',
          label: 'Do you have Pets or Animals?',
          type: 'checkbox',
          value: '1',
        },
        // {
        //   name: 'terms',
        //   label:
        //     'Check here to indicate that you have read and agree to our terms & conditions and privacy policy',
        //   type: 'checkbox',
        //   placeholder: '',
        // },
      ],
    },
    addressForm: {},
  },
  //Page Views
  components: {
    tabBar: {
      tabs: [
        {
          tab: 'home',
          href: '/dashboard/home',
          icon: home,
          label: 'Home',
          notification: getNotificationCount('home'),
          show: true,
        },
        {
          tab: 'appointments',
          href: '/dashboard/appointments',
          icon: calendarClear,
          label: 'Appointments',
          notification: getNotificationCount('appointments'),
          show: true,
        },
        {
          tab: 'therapists',
          href: '/dashboard/therapists',
          icon: personCircle,
          label: 'Therapists',
          notification: getNotificationCount('therapists'),
          show: true,
        },
        {
          tab: 'exercises',
          href: '/dashboard/exercises',
          icon: barbellOutline,
          // src :  require(@/app/assets/img/icon/physiotherapy.svg)"
          label: 'Exercises',
          notification: getNotificationCount('exercises'),
          show: true,
        },

        {
          tab: 'Invoices',
          href: '/dashboard/patientInvoices',
          icon: receiptOutline,
          label: 'Invoices',
          notification: getNotificationCount('invoices'),
          show: false,
        },
      ],
    },
    sideDrawer: {
      menuList: [
        {
          icon: home,
          href: '/dashboard/home',
          text: 'Dashboard',
        },
        {
          icon: personCircle,
          href: '/dashboard/home',
          text: 'My Therapists',
        },
        {
          icon: list,
          href: '/dashboard/home',
          text: 'Care Plans',
        },
      ],
      accountList: [
        {
          icon: person,
          href: '/user/profile',
          text: 'My Profile',
        },
        {
          icon: exit,
          href: '#',
          text: 'Logout',
          click: () => {
            authService.logout()
          },
        },
      ],
    },
  },
  view: {
    loginOnboarding: {
      slides: [
        {
          //   src: '@/app/assets/img/jpg/towel-twist.jpg',
          //   src: 'svg/hero__stock--1.svg',
          src: require('@/app/assets/img/svg/hero__stock--1.svg'),
          heading: 'Lets get you back to better',
          // subheading: 'The quick brown fox jumps over the lazy dog',
          content:
            'Welcome to Back2Better, where we aim to get you back to doing the things you love sooner.',
        },
        {
          src: require('@/app/assets/img/svg/hero__stock--3.svg'),
          heading: 'Specialist therapists who are experts in your condition.',
          // subheading: 'The quick brown fox jumps over the lazy dog',
          content:
            'Work with a vetted, therapist who is an expert in your condition or injury',
        },
        {
          src: require('@/app/assets/img/svg/hero__stock--3.svg'),
          heading: 'Care on your terms',
          // subheading: 'The quick brown fox jumps over the lazy dog',
          content:
            'Receive treatment in the comfort of your own home or if suitable, via a tele-consult.',
        },
        {
          src: require('@/app/assets/img/svg/hero__stock--6.svg'),
          heading: 'Guided rehab for faster recovery',
          content:
            'Your therapist will diagnose and treat your condition or injury and prescribe guided, monitored, in-app exercises, for you to complete in between appointments.',
          button: {
            text: 'Get Started',
          },
        },
      ],
    },
    onboarding: {
      template: {
        //   src: '@/app/assets/img/jpg/towel-twist.jpg',
        //   src: 'svg/hero__stock--1.svg',
        src: require('@/app/assets/img/svg/hero__stock--1.svg'),
        heading: 'Easily book services that come to you',
        subheading: 'The quick brown fox jumps over the lazy dog',
        content:
          'Lorem ipsum dolor sit amet, **consectetur adipiscing** elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. \n\nUt enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        markdown:
          'Lorem ipsum dolor sit amet, **consectetur adipiscing** elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. \n\nUt enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      },
      home: {
        heading: "Let's get started",
        subheading: 'Book your first appointment',
      },
      appointments: {
        //   src: '@/app/assets/img/jpg/towel-twist.jpg',
        //   src: 'svg/hero__stock--1.svg',
        src: require('@/app/assets/img/svg/hero__stock--1.svg'),
        heading: 'Easily book services that come to you',
      },
      therapists: {
        //   src: '@/app/assets/img/jpg/towel-twist.jpg',
        //   src: 'svg/hero__stock--1.svg',
        src: require('@/app/assets/img/svg/hero__stock--1.svg'),
        heading: 'Find a therapist',
      },
      exercises: {
        //   src: '@/app/assets/img/jpg/towel-twist.jpg',
        //   src: 'svg/hero__stock--1.svg',
        src: require('@/app/assets/img/svg/hero__stock--1.svg'),
        heading: 'An exercise plan tailored for you',
      },
    },
    login: {
      heading: 'Login',
      subheading: 'Please sign in to book your appointment.',
      footer: "Don't have account? **[Sign Up](/auth/register)**",
    },
    forgotPassword: {
      heading: 'Forgot Password?',
      subheading: 'Verify your email address to reset your password',
      footer:
        '**Need Help?** Contact our **[Support Team](/home/support)**. \n\n  Already have an account? **[Login](/auth/login)**',
    },
    register: {
      heading: `Let's Get Started!`,
      subheading: `##### To book your first appointment you'll need to setup your account profile.\n\n By completing the account setup you are agreeing that you have read and accept the **[Terms&nbsp;&&nbsp;Conditions](#)** and **[Privacy&nbsp;Policy](#)** for use of this product.`,
    },
    registerSuccess: {
      heading: `Great! Check your inbox to complete setup.`,
      subheading: `##### We've sent you a verification email to confirm your details.\n\nIf you have not received an email check your SPAM folder or contact our support team for further help.\n\nYou will need to verify your account before you can book your first appointment.`,
      img: {
        src: require('@/app/assets/img/svg/hero__registration-success.svg'),
      },
      button: {
        text: 'Continue to Dashboard',
      },
    },
    birthdayCollectTerms: {
      heading: 'Why do we need to know your birthday?',
      body: 'We need to collect your birthdate so we know your birthday.',
    },
    privacyPolicy: {
      heading: 'Privacy Policy',
      body: '',
    },
    termsConditions: {
      heading: 'Terms & Conditions',
      body: '',
    },
    home: {
      tiles: [
        {
          id: 'appointments',
          src: require('@/app/assets/img/icon/calendar.svg'),
          text: 'Appointments',
          notification: getNotificationCount('appointments'),
          href: '/dashboard/appointments',
          enabled: true,
          show: true,
        },
        {
          id: 'therapists',
          src: require('@/app/assets/img/icon/mytherapist.svg'),
          text: 'My Therapists',
          notification: getNotificationCount('therapists'),
          href: '/dashboard/therapists',
          enabled: true,
          show: true,
        },
        {
          id: 'exercisePlans',
          src: require('@/app/assets/img/icon/physiotherapy.svg'),
          text: 'Care Plans',
          notification: getNotificationCount('exercises'),
          href: '/dashboard/exercises',
          enabled: true,
          show: true,
        },
        {
          id: 'messages',
          src: require('@/app/assets/img/icon/conversation.svg'),
          text: 'Messages',
          notification: getNotificationCount('messages'),
          href: '/user/messages',
          enabled: true,
          show: true,
        },
        {
          id: 'files',
          src: require('@/app/assets/img/icon/profile.svg'),
          text: 'My Files',
          notification: getNotificationCount('files'),
          href: '/files',
          enabled: true,
          show: true,
        },
        {
          id: 'paymentHistory',
          src: require('@/app/assets/img/icon/invoice.svg'),
          text: 'Payment History',
          notification: getNotificationCount('paymentHistory'),
          href: '/dashboard/patientInvoices',
          enabled: true,
          show: true,
        },
        {
          id: 'dashboard',
          src: require('@/app/assets/img/icon/seo-report.svg'),
          text: 'Dashboard',
          notification: getNotificationCount('dashboard'),
          href: '/dashboard/blank',
          enabled: true,
          show: true,
        },
        {
          id: 'letters',
          src: require('@/app/assets/img/icon/account.svg'),
          text: 'Letters',
          notification: getNotificationCount('letters'),
          href: '#',
          enabled: false,
          show: true,
        },
        {
          id: 'help',
          src: require('@/app/assets/img/icon/help.svg'),
          text: 'Help',
          notification: getNotificationCount('help'),
          href: '#',
          enabled: false,
          show: true,
        },
      ],
    },
    appointments: {
      newAppointment: {
        heading: 'Therapy',
        rows: {
          icon: '',
          src: '',
          label: 'Injury',
        },
      },
    },
  },
}
