import { modalController, type ModalOptions } from '@ionic/vue'
import { alertController } from '@ionic/vue'

export default {
  async open(options: ModalOptions) {
    const modal = await modalController.create(options)
    await modal.present()
    return modal.onDidDismiss()
  },
}

export const easyAlertConfirmation = async (
  message: string,
  header: string,
  onConfirm: () => void | Promise<void>
) => {
  const alert = await alertController.create({
    header: header,
    message: message,
    buttons: [
      {
        text: 'No',
        role: 'cancel',
        cssClass: 'secondary',
      },
      {
        text: 'Yes',
        handler: onConfirm,
      },
    ],
  })
  await alert.present()
}
