import { readonly } from 'vue'
import { environment } from '@/../environment'
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth'
import { SpinnerService } from './spinner-service'

// patientCarePlanList: '/patientCarePlanList',
// patientExerciseList: '/patientExerciseList',
// patientDailyActivity: '/patientDailyExerciseActivity',
// myTherapists: '/myTherapists',
// exerciseCompletePercentage: '/exerciseCompletePercentage',
// patientAppointmentPayment: '/patientAppointmentPayment',
// master: '/master',
// searchTherapists: '/searchTherapists',
// patientAddress: '/patientAddress',
// createAppointment: '/createPatientAppointment',
// updateAppointment: '/updatePatientAppointment',
// notificationsReadByPatient: '/notificationsReadByPatient',
// patientNotifications: '/patientNotifications',
// patientProfileDetails: '/patientProfileDetails',
// patientUpdateProfile: '/patientUpdateProfile',
// patientAddAddress: '/patientAddAddress',
// uploadPaymentFiles: '/uploadPaymentFiles',
// getAppointmentDetails: '/getAppointmentDetails',
// getInvoiceList: '/getInvoiceList',

import {
  Response,
  //UserType,
} from '@/app/types'
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { api } from '@/app/core/_helpers/api'
import { useAuthStore } from '@/app/stores/auth'
import { LocationQueryValue } from 'vue-router'
import { StrapiLoginData } from '@/app/types/api'
import { StrapiError } from '@/app/types/strapi'

// onload set local store and variable reactivity

export type AxiosResponseHeaders = {
  servertoken?: string
  usertoken?: string
}

const signIn = async (loginData: StrapiLoginData) => {
  const authStore = useAuthStore()

  const user = await authStore.getUser(loginData.jwt)

  // BUG: role is undefined
  // eslint-disable-next-line
  // @ts-ignore
  if (user.role?.name === 'provider') {
    throw 'You are not authorized to login'
  }

  if (loginData.jwt) {
    authStore.setToken(loginData.jwt, user)
  }
  return loginData
}

const login = async (loginData: any): Promise<StrapiLoginData> => {
    const data = {
      identifier: loginData.email,
      password: loginData.password,
    }
    try {
      const response = await api.post<StrapiLoginData>('/api/auth/local', data)

      return signIn(response.data)
    } catch (error) {
      throw (
        (error as AxiosError<StrapiError>).response?.data.error.message ||
        (error as AxiosError).message
      )
    }
  },
  // TODO: to remove
  loginWithStrapiProvider = (
    token: LocationQueryValue | LocationQueryValue[],
    provider: any
  ) => {
    return new Promise((resolve, reject) => {
      if (!token) {
        const error: Response = {
          message: 'Missing access token, please try again',
        }

        reject(error)
        return
      }

      api
        .get(`/api/auth/${provider}/callback/?access_token=${token}`)
        .then((response) => {
          const data = response.data
          const authStore = useAuthStore()
          const result: Response = {
            status: 'ok',
            data: data,
          }

          authStore.setToken(data.jwt, data.user)
          resolve(result)
        })
        .catch((error: any) => {
          const result: Response = {
            // status: 'ok',
            message: error.response?.data.error.message || error.message,
            data: error,
          }
          reject(result)
        })
    })
  }

const logout = () => {
  return new Promise((resolve) => {
    const authStore = useAuthStore()
    authStore.reset()

    const result: Response = {
      status: 'ok',
    }
    window.location.href = '/'
    resolve(result)
  })
}

const register = (formData: Record<string, any>) => {
  return new Promise((resolve, reject) => {
    const data = {
      email: formData.email,
      username: `username-${formData.email}`,
      password: formData.password,
    }
    api
      .post('/api/patient/register', data)
      .then((response: AxiosResponse<StrapiLoginData>) => {
        const authStore = useAuthStore()
        if (response.data.jwt) {
          authStore.setToken(response.data.jwt)
        }
        resolve(response.data)
      })
      .catch((error: AxiosError<StrapiError>) => {
        reject(error.response?.data.error.message || error.message)
      })
  })
}

const forgotPassword = (data: Record<string, string>) => {
  return api.post('api/auth/forgot-password', data)
}
const resetPassword = async (data: Record<string, string>) => {
  const response = await api.post<StrapiLoginData>(
    'api/auth/reset-password',
    data
  )
  if (response.data.jwt) {
    const authStore = useAuthStore()
    await authStore.setToken(response.data.jwt)
  }
  return response
}

const googleLogin = async () => {
  const data = await GoogleAuth.signIn()
  await new SpinnerService().open()
  const accessToken = data.authentication.accessToken

  const response = await api.get<StrapiLoginData>('/api/auth/google/callback', {
    params: {
      access_token: accessToken,
      appType: 'patient',
    },
  })

  return signIn(response.data)
}

export const authService = readonly({
  resetPassword,
  forgotPassword,
  login,
  register,
  logout,
  loginWithStrapiProvider,
  googleLogin,
})
