
import { defineComponent } from 'vue'
import {
  modalController,
  IonCard,
  IonRow,
  IonGrid,
  IonButtons,
  IonIcon,
  IonButton,
  IonLabel,
  IonCol,
} from '@ionic/vue'
import { add, closeOutline } from 'ionicons/icons'
import { storeToRefs } from 'pinia'

import { useRouter } from 'vue-router'
import dayjs from 'dayjs'
import { useAuthStore } from '../stores/auth'
import { api } from '@/app/core/_helpers/api'

export default defineComponent({
  name: 'NotificationModal',
  components: {
    IonCard,
    IonRow,
    IonGrid,
    IonButtons,
    IonIcon,
    IonButton,
    IonLabel,
    IonCol,
  },
  setup() {
    const { notifications } = storeToRefs(useAuthStore())
    return {
      notifications,
    }
  },
  data() {
    const router = useRouter()

    return {
      router,
      add,
      closeOutline,
      dayjs: dayjs,
      types: ['reschedule'],
    }
  },
  methods: {
    async dismissModal() {
      await modalController.dismiss()
    },
    clearAll() {
      this.clearNotifications(this.notifications.map((item) => item.id))
    },
    async clearNotifications(ids: number[]) {
      const authStore = useAuthStore()
      await api.patch('/api/notifications/read', { ids })
      await authStore.fetchNotifications()
    },
  },
})
