import DashboardView from '@/app/views/layout/DashboardView.vue'
import DashboardPageView from '@/app/views/layout/DashboardPageView.vue'
import DashboardWithoutTollbar from '@/app/views/layout/DashboardWithoutTollbar.vue'
import { App, URLOpenListenerEvent } from '@capacitor/app'
import Auth from '@/app/views/layout/Auth.vue'
import UserDashboard from '@/app/views/Pages/UserDashboard.vue'

import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'

import { useAuthStore } from '@/app/stores/auth'

// const authZToken =

async function requireAuth(to: any, from: any, next: any) {
  const authStore = useAuthStore()
  await authStore.ready
  if (authStore.user) {
    if (
      (!authStore.user.firstName || !authStore.user.lastName) &&
      !to.path.includes('auth/register')
    ) {
      return next('/auth/register/1')
    } else if (
      !authStore.pin &&
      !authStore.usePassword &&
      !to.path.includes('auth/register')
    ) {
      return next('/auth/register/2')
    } else if (
      to.path.includes('auth/register') &&
      authStore.user?.firstName &&
      authStore.user?.lastName &&
      (authStore.pin || authStore.usePassword)
    ) {
      return next('/')
    }

    next()
  } else next('/auth/login')
}
async function hasAuth(to: any, from: any, next: any) {
  const authStore = useAuthStore()

  await authStore.ready

  if (authStore.user) next('/dashboard/home')
  else next()
}

async function requireProfile(to: any, from: any, next: any) {
  const authStore = useAuthStore()

  if (
    !authStore.user?.addresses?.[0] ||
    !authStore.user?.dob ||
    !authStore.user?.gender ||
    !authStore.user?.mobileNumber ||
    !authStore.user?.profileImg
  ) {
    next('/user/profile')
  } else next()
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/dashboard/home',
  },
  //   {
  //     path: '/:pathMatch(.*)*',
  //     redirect: '/dashboard/home',
  //   },
  {
    path: '/dashboard/',
    component: DashboardView,
    // beforeEnter: requireAuth,
    children: [
      {
        path: '',
        redirect: '/dashboard/home',
      },
      {
        path: 'home',
        name: 'Dashboard',
        component: () => import('@/app/views/Pages/HomePage.vue'),
        beforeEnter: requireAuth,
      },
      {
        path: 'appointments',
        name: 'My Appointments',
        component: () => import('@/app/views/Pages/AppointmentsList.vue'),
        beforeEnter: [requireAuth, requireProfile],
      },
      {
        path: 'therapists',
        name: 'My Therapists',
        component: () => import('@/app/views/Pages/TherapistsPage.vue'),
        beforeEnter: requireAuth,
      },
      {
        path: 'exercises',
        name: 'My Exercises',
        component: () => import('@/app/views/Pages/ExercisePlan.vue'),
        beforeEnter: requireAuth,
      },
    ],
  },
  {
    path: '/user/dashboard/',
    name: 'user-dashboard',
    component: UserDashboard,
    beforeEnter: [requireAuth, requireProfile],
  },
  {
    path: '/user/settings',
    component: () => import('@/app/views/User/SettingsPage.vue'),
    name: 'Settings',
    beforeEnter: requireAuth,
  },
  {
    path: '/user/settings/quick-login',
    component: () => import('@/app/views/User/QuckLogin.vue'),
    name: 'Quick Login',
    beforeEnter: requireAuth,
  },
  {
    path: '/user/settings/login-history',
    component: () => import('@/app/views/User/LoginHistory.vue'),
    name: 'Login History',
    beforeEnter: requireAuth,
  },
  {
    path: '/user/settings/password',
    component: () => import('@/app/views/User/ChangePassword.vue'),
    name: 'Change password',
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/payment-history',
    name: 'My Invoices',
    component: () => import('@/app/views/Tab5.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/appointments/',
    component: DashboardPageView,
    children: [
      {
        path: 'booking',
        name: 'Book an Appointment',
        component: () => import('@/app/views/CalendarBooking.vue'),
      },
    ],
  },
  {
    path: '/exercises/:id/:date',
    name: 'Exercise Plan Details',
    component: () => import('@/app/views/Exercises/PlanDetails.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/payment-confirm/:id',
    name: 'Payment Confirmation',
    component: () => import('@/app/views/Appointments/PaymentConfirmed.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/user/profile',
    name: 'Profile',
    component: () => import('@/app/views/Pages/Settings.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/messages',
    name: 'chat',
    component: () => import('@/app/views/Pages/Messages.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/messages/admin',
    name: 'Admin Chat',
    component: () => import('@/app/views/Messages/AdminChat.vue'),
    beforeEnter: requireAuth,
  },

  // {
  //   path: '/files',
  //   name: 'Files',
  //   component: () => import('@/app/views/Files/Files.vue'),
  //   beforeEnter: requireAuth,
  // },
  // {
  //   path: '/files/upload',
  //   name: 'Upload Documents',
  //   component: () => import('@/app/views/Files/UploadDocument.vue'),
  //   beforeEnter: requireAuth,
  // },

  {
    path: '/auth',
    beforeEnter: hasAuth,
    component: Auth,
    children: [
      {
        path: '',
        redirect: '/auth/login',
      },
      {
        path: 'login',
        // component: Login,
        component: () => import('@/app/views/Auth/Login.vue'),
      },
      {
        path: 'register',
        // component: Register,
        component: () => import('@/app/views/Auth/Register.vue'),
      },
    ],
  },
  // {
  //   path: '/auth/login',
  //   // component: Login,
  //   component: () => import('@/app/views/Auth/Login.vue'),
  //   beforeEnter: hasAuth,
  // },
  // {
  //   path: '/auth/register',
  //   // component: Register,
  //   component: () => import('@/app/views/Auth/Register.vue'),
  //   beforeEnter: hasAuth,
  // },
  {
    path: '/auth/register/1',
    // component: Register,
    component: () => import('@/app/views/Auth/Register/Step1.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/auth/register/2',
    // component: Register,
    component: () => import('@/app/views/Auth/Register/Step2.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/auth/forgot-password',
    component: () => import('@/app/views/Auth/Forgot.vue'),
    beforeEnter: hasAuth,
  },
  {
    path: '/auth/reset-password/',
    component: () => import('@/app/views/Auth/Reset.vue'),
  },
  {
    path: '/mail-verification',
    component: () => import('@/app/views/authentication/MailVerification.vue'),
  },
  {
    path: '/reset-successful',
    component: () => import('@/app/views/authentication/ResetPasswordMail.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

App.addListener('appUrlOpen', function (event: URLOpenListenerEvent) {
  const slug = event.url.split('.app').pop()

  // We only push to the route if there is a slug present
  if (slug) {
    router.push({
      path: slug,
    })
  }
})

export default router
