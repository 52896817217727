
import { defineComponent } from 'vue'
import {
  IonRouterOutlet,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
} from '@ionic/vue'

export default defineComponent({
  name: 'DashboardPageView',
  components: {
    IonRouterOutlet,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
  },
  computed: {
    currentRouteName() {
      return this.$route.name
    },
  },
})
