import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Menu = _resolveComponent("Menu")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_Menu),
      _createVNode(_component_ion_tabs, null, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_component_ion_router_outlet, {
            key: _ctx.$route.path
          })),
          _createVNode(_component_ion_tab_bar, {
            slot: "bottom",
            mode: "md"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_tab_button, {
                tab: "tab1",
                href: "/dashboard/home"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.home }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Home")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_tab_button, {
                tab: "tab2",
                href: "/dashboard/appointments"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.calendarClear }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Appointments")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_tab_button, {
                tab: "tab3",
                href: "/dashboard/therapists"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { src: "/assets/icon/therapists.svg" }),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Therapists")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_tab_button, {
                tab: "tab4",
                href: "/dashboard/exercises"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { src: "/assets/icon/exercise.svg" }),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Plans")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}