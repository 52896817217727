
import { environment } from '@/../environment'
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Version',
  props: {
    display: {
      type: String,
      default: '',
    },
  },
  setup() {
    const RELEASE = environment.RELEASE
    return {
      RELEASE,
    }
  },
})
