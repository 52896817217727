import { defineStore } from 'pinia'
import { Storage } from '@ionic/storage'
import { environment } from '@/../environment'
import { StrapiUser } from '@/app/types/user'
import { api } from '../core/_helpers/api'
import { ref, type Ref } from 'vue'
import io, { Socket } from 'socket.io-client'

const authStore = new Storage()
interface notification {
  id: number
  title: string
  link: string
  type: string
  message: string
  createdAt: string
  updatedAt: string
}
interface UserStore {
  storeLoaded: Ref<boolean>
  token: Ref<string | null>
  user: Ref<StrapiUser | null>
  notifications: notification[]
  pin: Ref<string | null>
  pinAsk: Ref<Date | null>
  fingerprint: Ref<boolean | null>
  usePassword: Ref<boolean | null>
}

const getUser = async (token?: string) => {
  const response = await api.get<StrapiUser>('/api/users/me', {
    ...(token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {}),
    params: {
      populate: ['role', 'profileImg', 'addresses'],
    },
  })
  return response.data
}

export const useAuthStore = defineStore('user', {
  state: () => {
    const token = ref(null) as UserStore['token']
    const user = ref(null) as UserStore['user']
    const storeLoaded = ref(false) as UserStore['storeLoaded']
    const socket = ref(null) as Ref<Socket | null>
    const pin = ref(null) as Ref<string | null>
    const pinAsk = ref(null) as Ref<Date | null>
    const fingerprint = ref(null) as Ref<boolean | null>
    const usePassword = ref(null) as Ref<boolean | null>

    ;(async () => {
      await authStore.create()
      const t = await authStore.get(`${environment.storagePrefix}.jwt`)
      if (t) {
        token.value = t
        try {
          user.value = await getUser(t)
          storeLoaded.value = true
        } catch (e) {
          console.log(e)
        }
        socket.value = io(environment.strapiUrl, {
          autoConnect: false,
          auth: {
            token: token.value,
          },
        })
        socket.value.connect()
      } else {
        if (socket.value?.connected) {
          socket.value.disconnect()
        }
      }
      pin.value = await authStore.get(`${environment.storagePrefix}.pin`)
      pinAsk.value = await authStore.get(`${environment.storagePrefix}.pinAsk`)
      fingerprint.value = await authStore.get(
        `${environment.storagePrefix}.fingerprint`
      )
      usePassword.value =
        (await authStore.get(`${environment.storagePrefix}.usePassword`)) ||
        false
      storeLoaded.value = true
    })()

    return {
      storeLoaded,
      token,
      user,
      socket,
      notifications: [] as notification[],
      pin,
      pinAsk,
      fingerprint,
      usePassword,
    }
  },
  getters: {
    ready: (state) => {
      if (state.storeLoaded) return Promise.resolve(true)
      //waint until store is loaded
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          if (state.storeLoaded) {
            clearInterval(interval)
            resolve(true)
          }
        }, 100)
      })
    },
    extradata: (state) => (key: string) => {
      return state.user?.extradata?.[key]
    },
  },
  actions: {
    getUser,
    async reFetchUser() {
      if (!this.token) return

      const user = await getUser(this.token)
      this.user = user

      return user
    },
    setToken(token: string, user: StrapiUser | null = null) {
      this.storeLoaded = false
      return new Promise((resolve) => {
        authStore.set(`${environment.storagePrefix}.jwt`, token).then(() => {
          this.token = token
          this.storeLoaded = true
          if (this.socket?.connected) {
            this.socket.disconnect()
          }
          this.socket = io(environment.strapiUrl, {
            autoConnect: false,
            auth: {
              token: token,
            },
          })
          this.socket.connect()
          if (user) {
            this.user = user
            if (!this.user.extradata) {
              this.user.extradata = {}
            }
            return resolve({ token, user })
          }
          this.getUser(token).then((user) => {
            this.user = user
            resolve({ token, user })
          })
        })
      })
    },
    reset() {
      authStore.clear()
      this.token = null
      this.user = null
    },
    //TODO: create interface for userData
    async updateUser(userData: any) {
      const response = await api.put('api/user/me', userData)
      this.user = response.data
      return response
    },
    async fetchNotifications(archived = false) {
      const notifications = await api.get('/api/user/notifications/my', {
        params: {
          pagination: {
            limit: -1,
          },
          archived: archived ? 1 : 0,
        },
      })
      this.notifications = notifications.data.results || []
    },
    async setExtraData(key: string, value: any) {
      if (!this.user) {
        return
      }
      if (!this.user.extradata) {
        this.user.extradata = {}
      }
      this.user.extradata[key] = value
      const response = await api.put(`/api/user/me`, {
        extradata: this.user.extradata,
      })
      this.user.extradata = response.data.extradata
      return this.user.extradata?.[key]
    },
    setPin(pin: string) {
      authStore.set(`${environment.storagePrefix}.pin`, pin)
      this.pin = pin
      this.setPinAsk()
    },
    setPinAsk() {
      const nextAsk = new Date()
      nextAsk.setMinutes(nextAsk.getMinutes() + 15)
      authStore.set(`${environment.storagePrefix}.pinAsk`, nextAsk)
      this.pinAsk = nextAsk
    },
    async setFingerprint(enabled: boolean) {
      await authStore.set(`${environment.storagePrefix}.fingerprint`, enabled)
      this.fingerprint = enabled
    },
    async setUsePassword(enabled: boolean) {
      await authStore.set(`${environment.storagePrefix}.usePassword`, enabled)
      this.usePassword = enabled
      this.setPinAsk()
    },
  },
})
