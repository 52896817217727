//DEVELOP

const BRANCH = 'develop'
export const environment = {
  RELEASE: {
    VERSION: process.env.VUE_APP_VERSION,
    CREATED: process.env.VUE_APP_TIMESTAMP,
    BUILD: process.env.VUE_APP_BUILD,
  },
  branch: BRANCH,
  storagePrefix: `b2b.${BRANCH}`,
  production: false,
  baseAPIUrl: 'https://us-central1-back2better-38631.cloudfunctions.net', //TODO: Legacy - Remove
  // baseAPIUrl: "http://localhost:5001/back2better-38631/us-central1",
  appSecretKey: '64B21A21823GD512', //TODO: Legacy? - Remove
  authntoken: '', //TODO: Legacy? - Remove
  STRIPE_PUBLISHABLE_KEY: process.env.VUE_APP_STRIPE_KEY,
  serverToken: process.env.VUE_APP_API_KEY,
  strapiUrl: process.env.VUE_APP_STRAPI_API_URL,
}
