import { api } from '@/app/core/_helpers/api'

export const getImageUrl = (image: any, format: any): string => {
  return image.provider === 'local'
    ? api.defaults.baseURL + format.url
    : format.url
}

export const getMediumImage = (image: any) => {
  if (!image.attributes) {
    const format =
      image.formats?.thumbnail ||
      image.formats?.medium ||
      image.formats?.large ||
      image.formats?.xlarge ||
      image
    return getImageUrl(image, format)
  }
  const format =
    image.attributes.formats?.thumbnail ||
    image.attributes.formats?.medium ||
    image.attributes.formats?.large ||
    image.attributes.formats?.xlarge ||
    image.attributes
  return getImageUrl(image.attributes, format)
}

export const getSmalestImage = (image: any) => {
  if (!image.attributes) {
    const format =
      image.formats?.xsmall ||
      image.formats?.thumbnail ||
      image.formats?.small ||
      image.formats?.medium ||
      image.formats?.large ||
      image
    return getImageUrl(image, format)
  }
  const format =
    image.attributes.formats?.xsmall ||
    image.attributes.formats?.thumbnail ||
    image.attributes.formats?.small ||
    image.attributes.formats?.medium ||
    image.attributes.formats?.large ||
    image.attributes
  return getImageUrl(image.attributes, format)
}
