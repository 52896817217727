
import { defineComponent, computed } from 'vue'
import {
  IonButtons,
  IonIcon,
  IonBadge,
  modalController,
  IonButton,
} from '@ionic/vue'
import { notificationsOutline } from 'ionicons/icons'
import NotificationModal from '@/app/views/NotificationModal.vue'
import { useAuthStore } from '@/app/stores/auth'

// import { string } from 'yup/lib/locale'
export default defineComponent({
  name: 'NotificationIcons',
  components: {
    IonButtons,
    IonIcon,
    IonBadge,
    IonButton,
  },
  setup() {
    const authStore = useAuthStore()
    authStore.fetchNotifications()

    const notificationCount = computed(() => {
      return authStore.notifications.length
    })

    return {
      notificationCount,
    }
  },
  data() {
    // let notificationCount: string | null
    return {
      notificationsOutline,
    }
  },
  created() {
    //TODO: this needs to be moved to authServices
    // this.notificationCount = localStorage.getItem('notificationCount')
    //   ? parseInt(localStorage.getItem('notificationCount'))
    //   : '0'
  },
  methods: {
    async openNoticationModal() {
      const modal = await modalController.create({
        component: NotificationModal,
      })
      return modal.present()
    },
  },
})
