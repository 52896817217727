import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "is-size-7" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("small", null, [
      _createTextVNode(" Build: "),
      _createElementVNode("b", null, _toDisplayString(_ctx.RELEASE.BUILD), 1),
      _createTextVNode("  "),
      (_ctx.display !== 'inline')
        ? (_openBlock(), _createElementBlock("br", _hoisted_2))
        : _createCommentVNode("", true),
      _createTextVNode(" Version: "),
      _createElementVNode("b", null, _toDisplayString(_ctx.RELEASE.VERSION) + "-" + _toDisplayString(_ctx.RELEASE.CREATED), 1)
    ])
  ]))
}