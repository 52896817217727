import { loadingController } from '@ionic/vue'
import { lang } from '@/app/locale/en'

export class SpinnerService {
  async show(duration?: number) {
    const loading = await loadingController.create({
      cssClass: 'my-custom-class',
      message: lang.spinner.show,
      duration: duration || 2000,
    })

    await loading.present()

    setTimeout(function () {
      loading.dismiss()
    }, 2000)
  }
  async close() {
    await loadingController.dismiss()
  }
  async open() {
    const loading = await loadingController.create({
      cssClass: 'my-custom-class',
      message: lang.spinner.open,
    })

    await loading.present()
  }
}
