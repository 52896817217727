import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!

  return (_openBlock(), _createBlock(_component_ion_buttons, {
    slot: "secondary",
    onClick: _ctx.openNoticationModal
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_button, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, {
            slot: "icon-only",
            size: "large",
            icon: _ctx.notificationsOutline
          }, null, 8, ["icon"]),
          (_ctx.notificationCount > 0)
            ? (_openBlock(), _createBlock(_component_ion_badge, {
                key: 0,
                color: "secondary"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.notificationCount), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onClick"]))
}